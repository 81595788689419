<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Advertising from '@/services/Advertising';
  import ModalViewTeamDeal from "@/components/modals/advertising/modalViewTeamDeal";
  import ModalViewDeal from "@/components/modals/advertising/modalViewDeal";
  import ModalEditTeamDeal from "@/components/modals/advertising/modalEditTeamDeal";
  import ModalAddTeamDealManual from "@/components/modals/advertising/modalAddTeamDealManual";
  import ModalCopyTeamDeal from "@/components/modals/advertising/modalCopyTeamDeal";

  import Swal from "sweetalert2";

  export default {
  components: { Layout, PageHeader, ModalViewTeamDeal, ModalViewDeal, ModalEditTeamDeal, ModalAddTeamDealManual, ModalCopyTeamDeal },
  page: {
      title: "Deals",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  data() {
    return {
        accessView: JSON.parse(process.env.VUE_APP_SIDE_MENU),
        tenant: process.env.VUE_APP_TENANT,
        display_team:"display:none",
        error: null,
        filterData: false,
        filterDataAll: false,

        tableData: [],
        title: "Deals",
        items: [
          {
            text: "Advertising",
          },
          {
            text: "Deals",
            active: true,
          },
        ],
        filterInput: {
        },

        regionOptions:[],
        sectorOptions:[],
        currencyOptions:[],
        isBusy: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "valid_from",
        sortDesc: true,
        dealData:[],
        teamDealData:[],
        fields: [
           {
              key: "team_id",
              label: "Team ID",
              visible:false,

            },
            {
              key: "name",
              label: "Name",
              visible:true,
            },
            {
              key: "voucher_code",
              label: "Voucher Code",
              visible:true,
            },
            {
              key: "description",
              label: "Description",
              visible:true,
            },
            {
              key: "discount_type",
              label: "Discount Type",
              sortable: true,
              visible:true,
            },
            {
              key: "discount",
              label: "Discount",
              sortable: true,
              visible:true,
            },
            {
              key: "min_amount",
              label: "Min Amount",
              sortable: true,
              visible:true,
            },
            {
              key: "valid_from",
              label: "Valid From",
              sortable: true,
            },
            {
              key: "valid_to",
              label: "Valid To",
              sortable: true,
            },
            {
              key: "currency_code",
              label: "Currency",
              sortable: true,
            },
            {
              key: "region_country_code",
              label: "Region",
              sortable: true,
            },
            {
              key: "primary_sector",
              label: "Sector",
              sortable: true,
            },
            {
              key: "manual",
              label: "Manual",
              sortable: true,
            },
            {
              key: "status",
              label: "Status",
              sortable: true,
            },
            {
               key: "Action",
               label: "Action",
               thStyle: { width: "12%" },
            }
        ],

        tableDataAll: [],
        titleAll: "All Deals",
        itemsAll: [
          {
            text: "Deals",
          },
          {
            text: "Deals",
            active: true,
          },
        ],
        filterInputAll: {
            currency: '',
            region: '',
            sector: '',
            promotion: '',
        },
        statusOptions:[],
        isBusyAll: false,
        totalRowsAll: 0,
        currentPageAll: 1,
        perPageAll: 10,
        pageOptionsAll: [10, 25, 50, 100],
        filterAll: null,
        filterOnAll: [],
        sortByAll: "name",
        sortDescAll: true,
        dealDataAll:[],
        fieldsAll: [
            {
              key: "name",
              label: "Name"
            },
            {
              key: "description",
              label: "Description"
            },
            {
                key: "logo_url",
                label: "Logo Url",
                sortable: true,
            },
            {
              key: "currency_code",
              label: "Currency",
              sortable: true,
            },
            {
              key: "region_country_code",
              label: "Region",
              sortable: true,
            },
            {
              key: "primary_sector",
              label: "Sector",
              sortable: true,
            },
            {
              key: "promotion_exist",
              label: "Promotion",
              sortable: true,
            },
            {
              key: "status",
              label: "Status",
              sortable: true,
            },
            "Action"

        ],
        
    };
  },

  async created() {
    this.getDeals();
    this.getDealsAll();
    this.getCurrency();
    this.getRegion();
    this.getSector();
  },


  filters: {
         truncate: function (text, length, suffix) {
              if(text !== null){
                  if (text.length > length) {
                      return text.substring(0, length) + suffix;
                  } else {
                      return text;
                  }
              } else {
                return '';
              }
          },

      },

  methods: {

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      onFilteredAll(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsAll = filteredItems.length;
        this.currentPageAll = 1;
      },

      async getDeals(){

          try {
           // if(this.tenant !== "MON") {
          //      this.fields[0].thStyle = 'display:block';
           // } else {
           //  this.fields[0].thStyle = 'display:none';
          //  }

            this.filterData = true;
            this.isBusy = true;
            //this.toggleBusy();
            var filters = '';
            const response = await Advertising.getDeals(filters);
            this.tableData = response.data.data
            this.totalRows = this.tableData.length
            this.isBusy = false;
            //this.toggleBusy();
          } catch (error) {
            //this.toggleBusy();
            this.isBusy = false;
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
            this.totalRows = 0
          }
      },

      async getDealsAll(){
          try {
              this.filterDataAll = true;
              //this.toggleBusy();
              this.isBusyAll = true;
              var filters = this.getFiltersAll();
              const response = await Advertising.getDealsAll(filters);
              this.tableDataAll = response.data.data
              this.totalRowsAll = this.tableDataAll.length
              //this.toggleBusy();
              this.isBusyAll = false;

            } catch (error) {
              //this.toggleBusy();
              this.isBusyAll = false;
              this.error = error.response.data.error ? error.response.data.error : "";
              this.tableDataAll = []
              this.totalRowsAll = 0
            }
      },

      async getCurrency(){
          try {
              const response = await Advertising.getDealsCurrency();
              this.currencyOptions = [''].concat(response.data.data);

          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
          }
      },

      async getRegion(){
          try {
              const response = await Advertising.getDealsRegion();
              this.regionOptions = [''].concat(response.data.data);

          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
          }
      },

      async getSector(){
          try {
              const response = await Advertising.getDealsSector();
              this.sectorOptions = [''].concat(response.data.data);

          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
          }
      },



     getFilters(){
       var filter_string = '';
       return filter_string;
     },

      getFiltersAll(){
          var filter_string = '';

          if(this.filterInputAll.region) {
            filter_string += (this.filterInputAll.region) ? '&region=' + this.filterInputAll.region : "";
          }
          if(this.filterInputAll.sector) {
         // encodeURIComponent("&quot;Busola&quot;");
            filter_string += (this.filterInputAll.sector) ? '&sector=' + encodeURIComponent(this.filterInputAll.sector) : "";
          }

          if(this.filterInputAll.currency) {
            filter_string += (this.filterInputAll.currency) ? '&currency=' + this.filterInputAll.currency : "";
          }
          if(this.filterInputAll.promotion) {
            filter_string += (this.filterInputAll.promotion) ? '&promotion=' + this.filterInputAll.promotion : "";
          }

          return filter_string = filter_string.substring(1);
      },

      callModalViewTeamDeal(data) {
        this.$bvModal.show("view_team_deal");
        this.teamDealData = data
      },

      callModalViewDeal(data) {
        this.$bvModal.show("view_deal");
        this.dealData = data
      },

      callModalEditTeamDeal(data) {
        this.$bvModal.show("edit_team_deal");
        this.teamDealData = data
      },

      callModalAddTeamDealManual(data) {
        this.$bvModal.show("add_team_deal_manual");
        this.teamDealData = data
      },

      callModalCopyTeamDeal(data) {
        this.$bvModal.show("copy_team_deal");
        this.teamDealData = data
      },


      removeTeamDeal(id){
        Swal.fire({
          title: "Are you sure?",
          text: "Remove From Deals!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
                Advertising.deleteTeamDeal(id)
                    .then((response) => {
                      const res   = response.data.data  ? response.data.data  : false;
                      const error = response.data.error ? response.data.error : '';
                      if(res && error==''){
                        this.getDeals();
                        this.getDealsAll();
                        this.successmsg("Deal Successfully Removed")
                      }else{
                        this.failedmsg("Deal Removal Failed")
                      }
                    })
                    .catch(error => {
                      this.failedmsg('Fail!',error)
                });
          }
        });
      },

      changeDealStatus(id, status){


        Swal.fire({
          title: "Are you sure?",
          text: "Active deals will show up immediately in the app!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.value) {
                Advertising.editDeal(id, { status: status })
                    .then((response) => {
                      const res   = response.data.data  ? response.data.data  : false;
                      const error = response.data.error ? response.data.error : '';
                      if(res && error==''){
                        this.getDeals();
                        this.getDealsAll();
                        this.successmsg("The Deal is active!")
                      }else{
                        this.failedmsg("Status change failed!")
                      }
                    })
                    .catch(error => {
                      this.failedmsg('Fail!',error)
                });
          }
        });
      },


      resetFiltersAll(){
          for (const prop of Object.getOwnPropertyNames(this.filterInputAll)) {
              if(Array.isArray(this.filterInputAll[prop])){
                  this.filterInputAll[prop] = [];
              }else{
                  this.filterInputAll[prop] = "";
                  let refName = prop+'Autocomplete';
                  if(this.$refs[refName]){
                      this.$refs[refName].inputValue = "";
                  }
              }
          }
          this.tableData = [];
      },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :fields="fields"/>
    <div class="row">
      <div class="col-12">

        <div class="card" v-show="filterData">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                 @click="callModalAddTeamDealManual">
                <i class="mdi mdi-plus me-2"></i>Create Deal Manually
            </a>

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center" >
                <h4 class="card-title m-0">Total Team Deals: {{totalRows}}</h4>
              </div>
            </div>

            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                        id="deals-table_filter"
                        class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0 font-size-12" v-show="filterData">
              <b-table
                      :items="tableData"
                      :busy="isBusy"
                      :fields="fields"
                      :visible="fields.visible"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      empty-text="No Data Found"
              >

                <template  v-slot:cell(team_id)="data" >
                    <div>{{ data.item.team_id  }}</div>
                </template>

                <template v-slot:cell(description)="data" >
                    <div>{{ data.item.description | truncate(20, '...') }}</div>
                </template>

                <template v-slot:cell(manual)="data">
                    <span v-if="data.item.manual == 1 ">
                   <i class="fa fa-check"></i>
                   </span>
                </template>


                <template v-slot:cell(status)="data">
                  <div class="font-size-16 text-center" v-html="format_status(data.item.status)"></div>
                  <div v-if="data.item.status === 'pending' " class="text-center">
                        <a href="javascript:void(0);" class="px-2 " title="Remove Deal" @click="changeDealStatus(data.item.id, 'active')">
                          Make active
                        </a>
                  </div>
                </template>


                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                          href="javascript:void(0);"
                          @click="callModalViewTeamDeal(data.item)"
                          class="px-2 text-primary"
                          title="View Deal"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Deal" @click="callModalEditTeamDeal(data.item)">
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Deal" @click="removeTeamDeal(data.item.id)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                    <li v-if="tenant == 'MON' " class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-primary" title="Copy Deal" @click="callModalCopyTeamDeal(data.item)">
                        <i class="uil uil-copy font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
             <!--Table end -->

            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

         </div>
        </div>

  <PageHeader :title="titleAll" />
       <div class="card">
          <div class="card-body">

            <div class="row mt-4">
             <!-- <div class="col-md-12">
                <div class="mb-3">
                  <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                     @click="callModalAddAdvertisement">
                    <i class="mdi mdi-plus me-2"></i>
                    Create New
                  </a>
                </div>
              </div> -->
              <div class="col-md-12">
                <div class="custom-accordion">
                  <a
                          class="text-body fw-semibold pb-2 d-block"
                          data-toggle="collapse"
                          href="javascript: void(0);"
                          role="button"
                          aria-expanded="false"
                          v-b-toggle.categories-collapse
                  >
                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                  </a>
                  <b-collapse visible id="categories-collapse">
                    <div class="card p-2 border shadow-none">
                      <div class="row">

                        <div class="col-sm-12 col-md-3">
                          <label class="control-label form-label">Region</label>
                          <b-form-select class="form-control" aria-describedby="status-feedback" :options="regionOptions" v-model="filterInputAll.region"></b-form-select>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <label class="control-label form-label">Sector</label>
                          <b-form-select class="form-control" aria-describedby="status-feedback" :options="sectorOptions" v-model="filterInputAll.sector"></b-form-select>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <label class="control-label form-label">Currency</label>
                          <b-form-select class="form-control" aria-describedby="status-feedback" :options="currencyOptions" v-model="filterInputAll.currency"></b-form-select>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <label class="control-label form-label">Promotions Only </label><br>
                         <input type="checkbox" id="promotion" class="form-check-input" true-value="1" false-value="0" v-model="filterInputAll.promotion">
                        </div>

                      </div>

                      <div class="row mt-3">
                        <div class="col d-inline-flex">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="getDealsAll">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFiltersAll">Reset</button>
                        </div>
                      </div>

                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>




    <!-- All deals -->
 <div class="row mt-4">
              <div class="col d-inline-flex align-items-center" >
                <h4 class="card-title m-0">Total Deals: {{totalRowsAll}}</h4>
              </div>
            </div>

            <div class="row mt-4" v-show="filterDataAll">
              <div class="col-sm-12 col-md-6">
                <div id="deal-all-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPageAll"
                            size="sm"
                            :options="pageOptionsAll"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                        id="deals-all-table_filter"
                        class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filterAll"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0 font-size-12" v-show="filterData">
              <b-table
                      :items="tableDataAll"
                      :busy="isBusyAll"
                      :fields="fieldsAll"
                      responsive="sm"
                      :per-page="perPageAll"
                      :current-page="currentPageAll"
                      :sort-by.sync="sortByAll"
                      :sort-desc.sync="sortDescAll"
                      :filter="filterAll"
                      :filter-included-fields="filterOnAll"
                      @filtered="onFilteredAll"
                      show-empty
                      empty-text="No Data Found"
              >
                <template v-slot:cell(name)="data">
                    <a :href="data.item.display_url" class="nav-link">
                        <span>{{data.item.name}}</span>
                    </a>
                </template>

                <template v-slot:cell(logo_url)="data">
                    <span v-if="data.item.logo_url">
                        <img :src="data.item.logo_url" contain width="100px">
                    </span>
                </template>

               <template v-slot:cell(description)="data" >
                    <div>{{ data.item.description | truncate(20, '...') }}</div>
                </template>

                <template v-slot:cell(promotion_exist)="data">
                    <span v-if="data.item.promotion_exist == 1 ">
                   <i class="fa fa-check"></i>
                   </span>
                </template>

                <template v-slot:cell(status)="data">
                  <div class="font-size-16" v-html="format_status(data.item.status)"></div>
                </template>

                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                     <a
                          href="javascript:void(0);"
                          @click="callModalViewDeal(data.item)"
                          class="px-2 text-primary"
                          title="View Deal"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </a>
                    <!--  <a v-if="data.item.exist == 0 "
                          href="javascript:void(0);"
                          @click="callModalAddTeamDeal(data.item)"
                          class="px-2 text-primary"
                          title="Add"
                      >
                        <i class="uil uil-arrow-up font-size-18"></i>
                      </a> -->
                    </li>

                  </ul>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
             <!--Table end -->

            <div class="row" v-show="filterDataAll">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPageAll"
                            :total-rows="totalRowsAll"
                            :per-page="perPageAll"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>


    <!-- MODALS -->
    <ModalViewTeamDeal :teamDealData="teamDealData"></ModalViewTeamDeal>
    <ModalViewDeal :dealData="dealData"  @show="getPromotions()" @onRefresh="getDeals()"></ModalViewDeal>
    <ModalEditTeamDeal :teamDealData="teamDealData" @onRefresh="getDeals()"></ModalEditTeamDeal>
    <ModalCopyTeamDeal :teamDealData="teamDealData" @onRefresh="getDeals()"></ModalCopyTeamDeal>
    <ModalAddTeamDealManual :teamDealData="teamDealData" @onRefresh="getDeals(); getDealsAll();"></ModalAddTeamDealManual>

    <!-- /MODALS -->

  </Layout>
</template>
