<script>
    import {minLength, numeric, required, maxDecimals, decimal} from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Advertising from '@/services/Advertising';
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Swal from "sweetalert2";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        components:{   flatPickr ,validationMessages, vueDropzone: vue2Dropzone,},
        props: ['teamDealData'],
        watch: {
            teamDealData: function(newVal) {
                this.id = newVal.id;
                this.name = newVal.name;
                this.voucher_code = newVal.voucher_code;
                this.description = newVal.description;

                this.discount = newVal.discount_number;
                if(this.discount_type !== 'text'){
                    this.show_discount = true;
                }
                this.discount_type = newVal.discount_type;
                this.discount_text = newVal.discount_text;
                if(this.discount_type == 'text'){
                    this.show_discount_text = true;
                }
                this.min_amount = newVal.min_amount;
                this.currency_code = newVal.currency_code;
                this.valid_from = newVal.valid_from;
                this.valid_to = newVal.valid_to;
                this.logo_url = newVal.logo_url;
                this.display_url = newVal.display_url;
                this.click_through_url = newVal.click_through_url;
                this.region_name = newVal.region_name;
                this.region_country_code = newVal.region_country_code;
                this.primary_sector = newVal.primary_sector;
                this.terms = newVal.terms;
                this.status = newVal.status;
                this.deal_img_small = newVal.deal_img_small;
                this.deal_img_large = newVal.deal_img_large;

            }
        },

        data() {
            return {
                id:  '',
                name: '',
                voucher_code: '',
                description:'',
                discount:'',
                show_discount: false,
                discount_text:'',
                show_discount_text: false,
                discount_type:'',
                min_amount:'',
                currency_code:'',
                valid_from:'',
                valid_to:'',
                logo_url:'',
                display_url:'',
                click_through_url:'',
                region_name:'',
                region_country_code:'',
                primary_sector:'',
                terms:'',
                status:'',
                showModal: false,
                currencyOptions: [],
                statusOptions: ['active', 'inactive', 'pending'],
                submitted: false,
                tryingToSubmit: false,
                showLoader: false,
                csrf_token: localStorage.getItem('csrf_token'),
                discountTypeOptions:['percent', 'amount', 'text'],
                deal_img_small: '',
                deal_img_large: '',
                file_small: '',
                file_large: '',
                source_url: '',
                image_removed: false,
                toggleActive: 1,
                dropzoneOptions: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*'
                },
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*',
                    maxFiles: 2,
                },
                tryingToEdit: false,
                 show_add_image_small:false,
                 show_add_image_large:false,
            };
        },

        validations: {
            name: {
                required,
            },
          description: {
                required,
            },
           //  discount: {
          //      required,
          //      decimal
         //   },
            discount_type: {
                required
            },
            min_amount: {
                required,
                decimal
            },
            valid_from: {
                required
            },
            valid_to: {
                required
            },
           // logo_url: {
           //     required
           // },
            display_url: {
                required
            },
            click_through_url: {
                required
            },
          //  status: {
          //      required
         //   },
         },


        methods: {
            closeModal() {
                this.showModal = false;
                this.submitted = false;
                this.tryingToEdit = false;
                this.show_add_image_small = false;
                this.show_add_image_large = false;
            },

           selectFileSmall(files){
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one small image')

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary.removeFile(value)
                        });
                    }

                    this.file_small = queuedFiles[0];
                 }, 250);
            },

            selectFileLarge(files){
               setTimeout(() => {
                   let queuedFiles2 = this.$refs.myVueDropzone.getQueuedFiles();
                   if (queuedFiles2.length > 1) {
                       this.failedmsg('You can only have one large image')

                       files.forEach((value) => {
                           this.$refs.myVueDropzone.removeFile(value)
                       });
                   }

                   this.file_large = queuedFiles2[0];
                }, 250);
            },


             async editDeal(){
                this.tryingToEdit = true;
                    this.submitted = true;
                    this.$v.$touch()

                    if (this.$v.$invalid) {
                        this.tryingToEdit = false;
                        return;
                    } else {
                         let formData = new FormData();
                         if(this.file_small){
                             formData.append('file_small', this.file_small)
                         }
                         if(this.file_large){
                             formData.append('file_large', this.file_large)
                         }

                         //formData.append('source_url', this.source_url);
                        // formData.append('id', this.id);
                         formData.append('name', this.name);
                         formData.append('voucher_code', this.voucher_code);
                         formData.append('description', this.description);
                        // formData.append('discount', this.discount);
                         if(this.discount_type == 'text'){
                             formData.append('discount_text', this.discount_text);
                        } else {
                             formData.append('discount', this.discount);
                        }


                         formData.append('discount_type', this.discount_type);
                         formData.append('min_amount', this.min_amount);
                         formData.append('valid_from', this.valid_from);
                         formData.append('valid_to', this.valid_to);
                         formData.append('logo_url', this.logo_url);
                         formData.append('display_url', this.display_url);
                         formData.append('click_through_url', this.click_through_url);
                         formData.append('terms', this.terms);
                         formData.append('status', this.status);
                         formData.append('csrf_token', this.csrf_token);
                        // formData.append('text', this.text);


                         try {
                             await Advertising.editDeal(this.id, formData).then((response) => {

                                 const error   =  typeof response.error !== 'undefined' ? response.error  : false;

                                 if(error==''){
                                     this.successmsg("Deal successfully updated");
                                     this.closeModal();
                                 }else{
                                     this.failedmsg("Failed to update deal")
                                 }

                             }).catch(error => {
                                 this.error = error.response.data.error ? error.response.data.error : "";
                                 this.failedmsg(this.error)
                             }).finally(() => {
                                 this.refreshData()
                                 this.tryingToSubmit = false;
                             })
                         } catch (error) {
                             this.error = error.response.data.error ? error.response.data.error : "";
                             this.failedmsg("Deal not updated");
                         }
                    }
                 this.tryingToSubmit = false;
             },
             refreshData() {
                this.$emit('onRefresh') //event from parent
             },

              handleDiscountTypeChange(){

                 if(this.discount_type == 'text'){
                     this.show_discount = false;
                     this.show_discount_text = true;
                 } else {
                     this.show_discount = true;
                     this.show_discount_text = false;
                 }
              }

        }
    };


</script>

<template>
      <b-modal id="edit_team_deal" size="xl" v-model="showModal" title="Edit Deal" title-class="font-18">
         <form @submit.prevent="editDeal()">
            <div class="row" v-if="teamDealData">
             <div v-if="$v.$error" class="text-danger">Complete all fields</div>
                <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">

                    <b-tab title="Deal Data" active title-item-class="mb-2">
                        <b-card-text>
                             <div class="row">
                                <div class="col-sm-6">
                                <div v-if="$v.$error">{{$v.$error}}</div>
                                     <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                                            <b-form-input  v-model.trim="name"  :class="{ 'is-invalid': submitted && $v.name.$error}"></b-form-input>
                                            <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>
                                      </b-form-group>

                                      <b-form-group label="Voucher Code" label-for="formrow-voucher_code-input" class="mb-3">
                                             <b-form-input  v-model.trim="voucher_code"  ></b-form-input>
                                      </b-form-group>

                                      <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                                             <b-form-input v-model.trim="description" :class="{ 'is-invalid': submitted && $v.description.$error}"></b-form-input>
                                             <validationMessages v-if="submitted" :fieldName="'Description'" :validationName="$v.description"></validationMessages>
                                        </b-form-group>

                                        <b-form-group label="Discount Type" label-for="formrow-discount_type-input" class="mb-3">
                                            <b-form-select @change="handleDiscountTypeChange" class="form-control" :class="{ 'is-invalid': submitted && $v.discount_type.$error}" :options="discountTypeOptions" v-model="discount_type" ></b-form-select>
                                            <validationMessages v-if="submitted" :fieldName="'Discount Type'" :validationName="$v.discount_type"></validationMessages>
                                        </b-form-group>

                                        <b-form-group v-if='show_discount' label="Discount" label-for="formrow-discount-input" class="mb-3">
                                            <b-form-input v-model.trim="discount"></b-form-input>
                                          <!--  <b-form-input v-model.trim="discount" :class="{ 'is-invalid': submitted && $v.discount.$error}"></b-form-input> -->
                                          <!--  <validationMessages v-if="submitted && show_discount" :fieldName="'Discount'" :validationName="$v.discount"></validationMessages>  -->
                                      </b-form-group>

                                      <b-form-group v-if='show_discount_text' label="Discount Text" label-for="formrow-discount_text-input" class="mb-3">
                                           <b-form-input v-model.trim="discount_text" ></b-form-input>
                                          <!-- <b-form-input v-model.trim="discount_text" :class="{ 'is-invalid': submitted && $v.discount_text.$error}"></b-form-input> -->
                                          <!-- <validationMessages v-if="submitted" :fieldName="'Discount Text'" :validationName="$v.discount_text"></validationMessages> -->
                                      </b-form-group>



                                        <b-form-group label="Minimum Amount" label-for="formrow-min_amount-input" class="mb-3">
                                            <b-form-input v-model="min_amount" :class="{ 'is-invalid': submitted && $v.min_amount.$error}"></b-form-input>
                                            <validationMessages v-if="submitted" :fieldName="'Minimum Amount'" :validationName="$v.min_amount"></validationMessages>
                                        </b-form-group>

                                        <b-form-group label="Currency" label-for="formrow-currency_code-input" class="mb-3">
                                             <b-form-input v-model="currency_code" :disabled=true ></b-form-input>
                                        </b-form-group>

                                         <b-form-group label="Valid From" label-for="formrow-valid_from-input" class="mb-3">
                                           <flat-pickr
                                                v-model="valid_from"
                                                :class="{ 'is-invalid': submitted && $v.valid_from.$error}"
                                                class="form-control mb-2"
                                                name="valid_from"
                                           />
                                           <validationMessages v-if="submitted" :fieldName="'Valid From'" :validationName="$v.valid_from"></validationMessages>
                                        </b-form-group>

                                        <b-form-group label="Valid To" label-for="formrow-valid_to-input" class="mb-3">
                                           <flat-pickr
                                                v-model="valid_to"
                                                :class="{ 'is-invalid': submitted && $v.valid_to.$error}"
                                                class="form-control mb-2"
                                                name="valid_to"
                                           />
                                           <validationMessages v-if="submitted" :fieldName="'Valid To'" :validationName="$v.valid_to"></validationMessages>
                                        </b-form-group>

                                      <input type="hidden" name="csrf_token" v-model="csrf_token">

                                </div>

                                <div class="col-sm-6">
                                  <b-form-group label="Logo URL" label-for="formrow-logo_url-input" class="mb-3">
                                      <b-form-input v-model="logo_url" ></b-form-input>
                                        <!-- <validationMessages v-if="submitted" :fieldName="'Logo URL'" :validationName="$v.logo_url"></validationMessages> -->
                                      </b-form-group>

                                       <b-form-group label="Display URL" label-for="formrow-display_url-input" class="mb-3">
                                          <b-form-input v-model="display_url"  :class="{ 'is-invalid': submitted && $v.display_url.$error}"></b-form-input>
                                          <validationMessages v-if="submitted" :fieldName="'Display URL'" :validationName="$v.display_url"></validationMessages>
                                      </b-form-group>

                                      <b-form-group label="Click Through URL" label-for="formrow-click_through_url-input" class="mb-3">
                                           <b-form-input v-model="click_through_url"  :class="{ 'is-invalid': submitted && $v.click_through_url.$error}"> </b-form-input>
                                           <validationMessages v-if="submitted" :fieldName="'Click Through URL'" :validationName="$v.click_through_url"></validationMessages>
                                      </b-form-group>

                                      <b-form-group label="Region Name" label-for="formrow-region_name-input" class="mb-3">
                                          <b-form-input v-model="region_name" :disabled=true></b-form-input>
                                      </b-form-group>

                                      <b-form-group label="Region Code" label-for="formrow-region_country_code-input" class="mb-3">
                                           <b-form-input v-model="region_country_code" :disabled=true></b-form-input>
                                      </b-form-group>

                                      <b-form-group label="Primary Sector" label-for="formrow-primary_sector-input" class="mb-3">
                                           <b-form-input v-model="primary_sector" :disabled=true></b-form-input>
                                      </b-form-group>

                                      <b-form-group label="Terms" label-for="formrow-terms-input" class="mb-3">
                                            <b-form-input v-model="terms"></b-form-input>
                                      </b-form-group>

                                    <b-form-group label="Status" label-for="formrow-status-input" class="mb-3">
                                           <b-form-select class="form-control" :options="statusOptions" v-model="status" ></b-form-select>

                                    </b-form-group>

                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>



                    <b-tab title="Images" title-item-class="mb-2">
                        <b-card-text class="text-center">

                            <div class="row mb-3">
                                <div class="col-6">
                                    <h5 class="text-center">Small Image</h5>


                                     <a  href="javascript:void(0);" class="btn btn-primary btn-sm" @click="show_add_image_small = !show_add_image_small">{{show_add_image_small ? 'View Image' : 'Change Small Image'}}</a>

                                     <template v-if="!show_add_image_small">
                                        <b-form-group label-for="image" class="mb-3 text-center image-area">
                                            <img :src="deal_img_small != '' ? deal_img_small : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail" >
                                        </b-form-group>
                                    </template>
                                    <template v-else>

                                        <vue-dropzone
                                                id="dropzone_primary"
                                                ref="myVueDropzone_primary"
                                                :use-custom-slot="true"
                                                :options="dropzoneOptions_primary"
                                                @vdropzone-files-added="selectFileSmall"
                                                no-progress-bar
                                        >
                                            <div class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <h4>Drop file here or click to upload.</h4>
                                            </div>
                                        </vue-dropzone>
                                    </template>


                                 <!--   <vue-dropzone
                                            id="dropzone_primary"
                                            ref="myVueDropzone_primary"
                                            :use-custom-slot="true"
                                            :options="dropzoneOptions_primary"
                                            @vdropzone-files-added="selectFileSmall"
                                            no-progress-bar
                                    >
                                        <div class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <h4>Drop file here or click to upload.</h4>
                                        </div>
                                    </vue-dropzone>
                                    -->
                                </div>


                                <div class="col-6 ">

                                    <h5>Large Image</h5>
                                     <a  href="javascript:void(0);" class="btn btn-primary btn-sm" @click="show_add_image_large = !show_add_image_large">{{show_add_image_large ? 'View Image' : 'Change Large Image'}}</a>
                                      <template v-if="!show_add_image_large">
                                        <b-form-group label-for="image" class="mb-3 text-center image-area">
                                            <img :src="deal_img_large != '' ? deal_img_large : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail" >
                                        </b-form-group>
                                    </template>

                                    <template v-else>
                                        <vue-dropzone
                                                id="dropzone"
                                                ref="myVueDropzone"
                                                :use-custom-slot="true"
                                                :options="dropzoneOptions"
                                                @vdropzone-files-added="selectFileLarge"
                                                no-progress-bar
                                        >
                                            <div class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </vue-dropzone>
                                     </template>
                                </div>
                            </div>

                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editDeal" :disabled="tryingToSubmit || showLoader">
                 <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
            </b-button>
        </template>

    </b-modal>
</template>

